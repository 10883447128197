<template>
    <div class="vertical-horizontal-center has-background-primary">
        <div class="columns is-gapless is-centered is-vcentered is-multiline is-flex">
            <div class="column is-7-mobile is-full-desktop">
                <b-image :src="require('../assets/harpia_logo_alt.png')" ratio="4by2"></b-image>
            </div>
            <div class="column is-7-mobile is-full-desktop">
                <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(Login)" class="has-text-left">
                        <b-field>
                            <ValidationProvider name="email" mode="passive" rules="required|email" v-slot="{ errors, untouched, changed }">
                                <b-field :type="{ 'is-light': untouched, 'is-light': changed, 'is-danger': errors[0] }">
                                    <template slot="label">
                                        <span class="has-text-weight-normal has-text-white-bis is-size-6 is-family-sans-serif">{{ $t('login.email') }}</span>
                                    </template>
                                    <b-input v-model="UserPayload.Email" size="is-medium" custom-class="has-background-primary has-text-white-bis" value="Kevin Garvey"></b-input>
                                </b-field>
                            </ValidationProvider>
                        </b-field>
                        <b-field>
                            <ValidationProvider name="password" mode="passive" rules="required" v-slot="{ errors, untouched, changed }">
                                <b-field :type="{ 'is-light': untouched, 'is-light': changed, 'is-danger': errors[0] }">
                                    <template slot="label">
                                        <span class="has-text-weight-normal has-text-white-bis is-size-6 is-family-sans-serif">{{ $t('login.password') }}</span>
                                    </template>
                                    <b-input v-model="UserPayload.Password" size="is-medium" custom-class="has-background-primary has-text-white-bis" value="Kevin Garvey" type="password"></b-input>
                                </b-field>
                            </ValidationProvider>
                        </b-field>
                        <b-field>
                            <p class="control has-text-centered">
                                <b-button class="control has-text-centered has-background-primary has-text-white-bis" size="is-small" type="is-link" @click="ForgotPassword">Esqueceu a senha?</b-button>
                            </p>
                        </b-field>
                        <p class="control has-text-centered">
                            <b-button class="has-text-primary has-custom-width" size="is-medium" native-type="submit" type="is-light" :loading="IsConfirmButtonLoading"><span class="is-family-sans-serif">{{ $t('login.confirm') }}</span></b-button>
                        </p>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.has-custom-width {
    width: 75%
}

.vertical-horizontal-center {
    height: 100vh;
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}
</style>
<script>
import { ValidationProvider } from 'vee-validate'
import { ValidationObserver } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'
extend('email', email)
extend('required', required)
export default {
    name: 'Landing',
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            UserPayload: { Email: '', Password: '' },
            IsConfirmButtonLoading: false
        }
    },
    methods: {
        Login() {
            this.IsConfirmButtonLoading = true
            this.$store.dispatch('user/Login', this.UserPayload)
                .then(() => {
                    this.IsConfirmButtonLoading = false
                    this.$router.replace('/').catch(() => {})
                })
                .catch(error => {
                    this.LaunchToast(error.message, 'is-danger');
                    this.IsConfirmButtonLoading = false
                })
        },
        ForgotPassword() {
            this.$buefy.dialog.prompt({
                message: `Qual seu email?`,
                inputAttrs: {
                    value: this.UserPayload.Email,
                    type: 'email'
                },
                trapFocus: true,
                confirmText: 'Enviar e-mail de recuperação',
                cancelText: 'Cancelar',
                onConfirm: (value) => {
                    this.$store.dispatch('user/SendPasswordReset', value)
                        .then(() => {
                            this.LaunchToast('Enviado um email para cadastro de nova senha.', 'is-success')
                        }).catch(error => {
                            this.LaunchToast(error, 'is-danger')
                        })
                }
            })
        },
        LaunchToast(message, type) {
            this.$buefy.toast.open({ //Displays toast with given properties below.
                message: message, //Message to display.
                type: type, //Toast's color.
                position: 'is-bottom', //Toast's position.
                duration: 3000 //Toast's duration.
            })
        }
    }
}
</script>